import React from "react";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import { useContext } from "react";

import { LanguageContext } from "../../../lang";
import "./index.css";

const redLocation = require("../../../images/airport-transport/red-location1.png");

const NetherlandAirports = () => {
  const {
    translations: { airportTransport },
  } = useContext(LanguageContext);
  const { cardtitle1, cardTitle2 } = airportTransport.netherlandAirports;
  return (
    <>
      <section id="netherland-airports">
        <div className="container ">
          <div className="row">
            <div className="col-lg-6">
              <Card>
                <CardBody className="netherland-airports-card">
                  <CardTitle className="netherland-airports-card-title">
                    {cardtitle1}
                  </CardTitle>
                  <ul className="netherland-airports-card-ul">
                    <li>
                      <p>
                        <img src={redLocation} alt="redLocation" />
                        <span>&nbsp;&nbsp;</span>Flughafen Frankfurt
                      </p>
                    </li>
                    <li>
                      <p>
                        <img src={redLocation} alt="redLocation" />
                        <span>&nbsp;&nbsp;</span>Flughafen München
                      </p>
                    </li>
                    <li>
                      <p>
                        <img src={redLocation} alt="redLocation" />
                        <span>&nbsp;&nbsp;</span>Flughafen Düsseldorf
                      </p>
                    </li>
                    <li>
                      <p>
                        <img src={redLocation} alt="redLocation" />
                        <span>&nbsp;&nbsp;</span>Flughafen SBerlin Tegel
                      </p>
                    </li>
                    <li>
                      <p>
                        <img src={redLocation} alt="redLocation" />
                        <span>&nbsp;&nbsp;</span>Flughafen Köln
                      </p>
                    </li>
                    <li>
                      <p>
                        <img src={redLocation} alt="redLocation" />
                        <span>&nbsp;&nbsp;</span>Flughafen Berlin-Schönefeld
                      </p>
                    </li>
                    <li>
                      <p>
                        <img src={redLocation} alt="redLocation" />
                        <span>&nbsp;&nbsp;</span>Flughafen Stuttgart
                      </p>
                    </li>
                    <li>
                      <p>
                        <img src={redLocation} alt="redLocation" />
                        <span>&nbsp;&nbsp;</span>EuroAirport
                        Basel-Mulhouse-Freiburg
                      </p>
                    </li>
                    <li>
                      <p>
                        <img src={redLocation} alt="redLocation" />
                        <span>&nbsp;&nbsp;</span>Flughafen Hannover
                      </p>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </div>
            <div className="col-lg-6">
              <Card>
                <CardBody>
                  <CardTitle className="netherland-airports-card-title">
                    {cardTitle2}
                  </CardTitle>
                  <ul className="netherland-airports-card-ul">
                    <li>
                      <p>
                        <img src={redLocation} alt="redLocation" />
                        <span>&nbsp;&nbsp;</span>Flughafen Eindhoven
                      </p>
                    </li>
                    <li>
                      <p>
                        <img src={redLocation} alt="redLocation" />
                        <span>&nbsp;&nbsp;</span>Den HaagFlughafen
                      </p>
                    </li>
                    <li>
                      <p>
                        <img src={redLocation} alt="redLocation" />
                        <span>&nbsp;&nbsp;</span>Flughafen Den Haag
                      </p>
                    </li>
                    <li>
                      <p>
                        <img src={redLocation} alt="redLocation" />
                        <span>&nbsp;&nbsp;</span>Flughafen Maastricht Aachen .
                      </p>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NetherlandAirports;
