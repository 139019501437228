import React from "react";

import "./index.css";
import HeroSection from "./Hero-Section";
import KnowMoreSection from "./../About/Know-More/index";
import EasyBooking from "./Easy-Booking";
import NetherlandAirports from "./Netherland-Airports";
import GermanAirports from "./German-Airports/index";

const AirportTransportation = () => {
  return (
    <>
      <HeroSection />
      <EasyBooking />
      <NetherlandAirports />
      <GermanAirports />
      <KnowMoreSection />
    </>
  );
};

export default AirportTransportation;
