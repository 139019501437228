import React from "react";

import Layout from "../components/Layout/layout";
import SEO from "../components/SEO/seo";
import AirportTransportation from "../components/Airport-transportation/index";

const AirportTransport = () => (
  <Layout>
    <SEO
      title="Taxi Kiel to Hamburg Airport | Taxi Neumünster to Hamburg Airport"
      meta="Taxi Kiel to Hamburg Airport | Taxi Neumünster to Hamburg Airport"
      description="Get affordable, hassle-free and on-time taxi from Hamburg airport to your destination with easy payment options and compatible seating facilities."
    />
    <AirportTransportation />
  </Layout>
);

export default AirportTransport;
