import React from "react";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import { useContext } from "react";

import { LanguageContext } from "../../../lang";
import "./index.css";

const yellowLocation = require("../../../images/airport-transport/yellow-location.png");

const GermanAirports = () => {
  const {
    translations: { airportTransport },
  } = useContext(LanguageContext);
  const { heading } = airportTransport.germanAirports;
  return (
    <>
      <section id="german-airports">
        <div className="container mr-5 mt-5">
          <div className="row">
            <h1 className="german-airports-heading">{heading}</h1>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <CardBody className="german-airports-card">
                <ul className="german-airports-card-ul">
                  <li>
                    <p>
                      <img src={yellowLocation} alt="yellowLocation" />
                      <span>&nbsp;&nbsp;</span>Flughafen Brüssel-Zaventem
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt="yellowLocation" />
                      <span>&nbsp;&nbsp;</span>Charleroi Brussels South Airporr
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt="yellowLocation" />
                      <span>&nbsp;&nbsp;</span>Flughafen Ostende-Brügge
                    </p>
                  </li>
                </ul>
              </CardBody>
            </div>
            <div className="col-lg-6">
              <CardBody>
                <ul className="german-airports-card-ul">
                  <li>
                    <p>
                      <img src={yellowLocation} alt="yellowLocation" />
                      <span>&nbsp;&nbsp;</span>Flughafen Antwerpen
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt="yellowLocation" />
                      <span>&nbsp;&nbsp;</span>Flughafen Lüttich
                    </p>
                  </li>
                </ul>
              </CardBody>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GermanAirports;
