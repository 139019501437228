import React from "react";
import "./index.css";
import { useContext } from "react";
import { LanguageContext } from "../../../lang";
const HeroSection = () => {
  const {
    translations: { airportTransport },
  } = useContext(LanguageContext);
  const { airport, transport } = airportTransport.hero;
  return (
    <section id="taxi-to-airport-hero-section">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="taxi-to-airport-h1">
              {airport}
              <span>{transport}</span>
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
