import React from 'react'
import { Link, navigate } from 'gatsby'
import { useContext } from 'react'

import { LanguageContext } from '../../../lang'
import OurCompanyImage from '../../Image/About/ourCompanyImage'
import './index.css'

const EasyBooking = () => {
  const {
    translations: { airportTransport }
  } = useContext(LanguageContext)
  const {
    heading,
    paragraph,
    buttonText
  } = airportTransport.easyBooking
  return (
    <>
      <div className='container pt-5'>
        <div className='row pt-5 pb-5'>
          <div className='col-lg-6 mt-lg-0 mt-md-4'>
            <h3 className='easy-booking-h3'>{heading}<br /></h3>
            <br />
            <p className='easy-booking-p'>
              {paragraph}
            </p>
            <br />
            <div className='col-12 easy-booking-button-div'>
              <div onClick={() => { navigate('/') }} className='easyBookingCalculatePriceButtonStyle'>
                <p className='easyBookingCalculatePriceButtonText'>{buttonText}</p>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <OurCompanyImage />
          </div>
        </div>
      </div>
    </>
  );
}

export default EasyBooking;